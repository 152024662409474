import axios from "axios";

export const API_PACS_IMAGE =
  "https://text-remove.ivirse.com/textdetectiondcm/";

const axiosUtils = {
  auth: "",
  serverApi: process.env.REACT_APP_AI_SERVER_URL,
  abortController: new AbortController(),
  requestAxios({
    method = "",
    url = "",
    params = {},
    data,
    timeout = 10,
    ignoreAuth = true,
    isUseServiceUrl = false,
    isUpload = false,
    keyUpload = "file",
    ...rest
  }) {
    console.log(rest);
    url = isUseServiceUrl ? this.serverApi + url : url;
    return new Promise((resolve, reject) => {
      let contentType = isUpload ? "multipart/form-data" : "application/json";
      if (isUpload) {
        const formData = new FormData();
        if (data?.length) {
          data.forEach((file) => {
            formData.append(keyUpload, file);
          });
        } else {
          formData.append(keyUpload, data);
        }
        data = formData;
      }
      console.log(url);
      axios({
        method,
        url,
        params,
        // timeout: timeout * 1000,
        data,
        headers: ignoreAuth
          ? { "Content-Type": contentType }
          : {
              "Content-Type": contentType,
              Authorization:
                this.auth || `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
            },
        // ...rest,
        signal: this.abortController.signal,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default axiosUtils;
