import API_VAR from "@constants/api";
import aiAxiosUtils from "@utils/ai-axios-utils";

export const PNEUMONIA_DOMAIN = () => {
  const domain = global.origin;
  switch (domain) {
    case "http://10.0.0.99:9105":
      return "http://103.9.211.87:8004";
    case "https://wallet-ivirse.isofh.vn":
    case "https://wallet.ivirse.com":
      return "https://pneumonia-api.ivirse.com";
    default:
      return "https://pneumonia-api.ivirse.com";
  }
};

export const SPINE_FRACTURE_DOMAIN = () => {
  const domain = global.origin;
  switch (domain) {
    case "http://10.0.0.99:9105":
      return "http://10.0.0.47:8000";
    case "https://wallet-ivirse.isofh.vn":
    case "https://wallet.ivirse.com":
      return "";
    default:
      return "http://10.0.0.47:8000";
  }
};

const AiUploadFileProvider = {
  upload: (body) => {
    return new Promise((resolve, reject) => {
      aiAxiosUtils
        .requestAxios({
          method: "POST",
          ignoreAuth: true,
          url: API_VAR.AI + "/prediction",
          isUseServiceUrl: true,
          data: body,
          isUpload: true,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  spineUpload: (body) => {
    return new Promise((resolve, reject) => {
      aiAxiosUtils
        .requestAxios({
          method: "POST",
          ignoreAuth: true,
          url: SPINE_FRACTURE_DOMAIN() + API_VAR.SPINE,
          isUseServiceUrl: false,
          data: body,
          isUpload: true,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  penumoniaUpload: (body) => {
    return new Promise((resolve, reject) => {
      aiAxiosUtils
        .requestAxios({
          method: "POST",
          ignoreAuth: true,
          url: PNEUMONIA_DOMAIN() + API_VAR.PNEUMONIA,
          isUseServiceUrl: false,
          data: body,
          isUpload: true,
          keyUpload: "image",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  pneumoniaPacsPred: (body) => {
    return new Promise((resolve, reject) => {
      aiAxiosUtils
        .requestAxios({
          method: "POST",
          ignoreAuth: true,
          url: PNEUMONIA_DOMAIN() + API_VAR.PNEUMONIA_PACS,
          isUseServiceUrl: false,
          data: body,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  getFilePathPneumonia: (path) => {
    return PNEUMONIA_DOMAIN() + path;
  },
};

export default AiUploadFileProvider;
