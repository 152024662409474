import { DisconnectOutlined, SettingOutlined } from "@ant-design/icons";
import { ProfileIcon } from "@assets/svg";
import ChangeLanguage from "@layouts/trade/header/components/ChangeLanguage";
import DropDownIcon from "@layouts/trade/header/components/DropDownIcon";
import NotificationsBell from "@layouts/trade/header/components/NotificationsBell";
import { Stack } from "@mui/material";
import store from "@redux/index";
import { size } from "@src/styles/modules/media-query";
import { strings } from "@utils/index";
import snackbarUtils from "@utils/snackbar-utils";
import classnames from "classnames";
import React, { cloneElement } from "react";
import { Link } from "react-router-dom";
import { ADMIN_ONLY_KEYS, ADMIN_TAB_ENUM } from "./constants";

const handleChangeTab = (tabIndex) => {
  const { dispatch } = store;
  dispatch.admin?.updateData({ activeNavItem: tabIndex });
};

const generateSubItems = ({ menus, isAdmin, history }) => {
  if (window.innerWidth > size.xl) return null;

  return menus.map((subItem, index) => {
    const path = `/admin-community?tabIndex=${index}`;
    subItem.onClick = () => {
      if (!isAdmin) {
        return snackbarUtils.error(strings("message.youAreNotAdmin"));
      }
      history.push(path);
      handleChangeTab(subItem.tabIndex);
    };
    subItem.href = path;
    return subItem;
  });
};

const checkProtectedRoutes = (menus, isAdmin, isMasterAdmin) => {
  const results = menus
    .map((menu) => {
      if (menu.subItems) {
        menu.subItems = menu.subItems.filter((subItem) => {
          return (
            !ADMIN_ONLY_KEYS.includes(subItem.key) || isAdmin || isMasterAdmin
          );
        });
      } else if (ADMIN_ONLY_KEYS.includes(menu.key)) {
        return isAdmin || isMasterAdmin ? menu : null;
      }
      return menu;
    })
    .filter((menu) => menu);

  return results;
};

export const getTradeHeaderItems = ({ history }) => {
  const { dispatch, getState } = store;
  const { isAdmin, isMasterAdmin } = getState().consensus;

  const menus = [
    {
      key: "wallet",
      label: "Wallet",
      subItems: [
        {
          key: "transfer",
          label: "Send IVI",
          href: "/transfer",
        },
        {
          key: "balances",
          label: "Balance",
          href: "/balances",
        },
        {
          key: "transfers-scan",
          label: "Transaction Logs",
          href: "/transfers-scan",
        },
      ],
    },
    {
      key: "datahub",
      label: "AIoMT",
      subItems: [
        {
          key: "data-hub",
          label: "AIoMT",
          href: "/data-hub",
        },
        {
          key: "data-hub/profile",
          label: "Profile",
          href: "/data-hub/profile",
        },
        {
          key: "data-hub/deposit",
          label: "Deposit/Withdraw",
          href: "/data-hub/deposit",
        },
      ],
    },
    {
      key: "tokenomics",
      label: "Tokenomics",
      subItems: [
        {
          key: "vesting",
          label: "Vesting",
          href: "/vesting",
        },
        {
          key: "community",
          label: "Community",
          href: "/community",
        },
        // {
        //   key: "staking",
        //   label: "Staking",
        //   href: "/staking",
        // },
      ],
    },
    {
      key: "data-hub/ai-demo-page",
      label: "Prediction",
      href: "/data-hub/ai-demo-page",
    },
    {
      key: "admin",
      label: "Admin",
      inSettingOnly: true,
      startIcon: <ProfileIcon />,
      subItems: [
        {
          key: "token-management",
          label: "Token Minting",
          href: "/token-management",
        },
        {
          key: "token-allocation",
          label: "Token Allocation",
          href: "/token-allocation",
        },
        {
          key: "admin-community",
          label: "General Management",
          onClick: () => {
            if (isAdmin) {
              localStorage.setItem("role", "admin");
              if (window.innerWidth > size.xl) {
                history.push("/admin-community");
              }
            } else {
              snackbarUtils.error(strings("message.youAreNotAdmin"));
            }
          },
          subItems: generateSubItems({
            menus: [
              {
                key: "notification",
                label: "Notification",
                tabIndex: ADMIN_TAB_ENUM.Notification,
              },
              {
                key: "admin-list",
                label: "Admin List",
                tabIndex: ADMIN_TAB_ENUM.AdminList,
              },
              {
                key: "token-management-admin",
                label: "Token Management",
                tabIndex: ADMIN_TAB_ENUM.TokenManagement,
              },
              {
                key: "campaign-management",
                label: "Campaign Management",
                tabIndex: ADMIN_TAB_ENUM.CampaignManagement,
              },
              {
                key: "staking-admin",
                label: "Staking",
                tabIndex: ADMIN_TAB_ENUM.Staking,
              },
            ],
            isAdmin,
            history,
          }),
        },
      ],
    },
    {
      key: "settings",
      label: "Settings",
      inSettingOnly: true,
      startIcon: <SettingOutlined />,
      subItems: [
        {
          key: "notifications",
          label: <NotificationsBell />,
        },
        {
          key: "language",
          label: <ChangeLanguage />,
        },
      ],
    },
    {
      key: "disconnect",
      label: "Disconnect",
      startIcon: <DisconnectOutlined />,
      inSettingOnly: true,
      onClick: () => {
        dispatch.contracts?.disconnect();
      },
    },
  ];

  const results = checkProtectedRoutes(menus, isAdmin, isMasterAdmin);
  return results;
};

export function generateMenu({ ...props }) {
  const {
    data,
    variant,
    path,
    ParentComponent,
    subMenuProps,
    subMenuTextProps,
    menuItemProps,
  } = props;

  const dropDownIconProps = { color: subMenuTextProps.color };

  return data.map((item) => {
    if (item.subItems) {
      const child = (
        <ParentComponent.NavSubMenuText {...subMenuTextProps}>
          {item.label}
          {variant && (
            <DropDownIcon
              className="icon-toggle-dropdown"
              {...dropDownIconProps}
            />
          )}
        </ParentComponent.NavSubMenuText>
      );
      return (
        <ParentComponent.NavSubMenu
          key={item.key}
          className={classnames("", {
            active:
              item.href === path ||
              path
                .split("/")
                .filter(Boolean)
                .includes(item.href?.slice(1) || ""),
          })}
          title={
            item.href ? (
              <Stack direction="row" alignItems="center" spacing={1}>
                {item.startIcon && item.startIcon}
                <Link className="ant-menu-link" to={item.href}>
                  {child}
                </Link>
              </Stack>
            ) : (
              cloneElement(child, { onClick: item.onClick })
            )
          }
          {...(subMenuProps || {})}
        >
          {generateMenu({
            ...props,
            variant: true,
            data: item.subItems,
          })}
        </ParentComponent.NavSubMenu>
      );
    }

    return (
      <ParentComponent.NavItem
        onClick={item.onClick}
        key={item.key}
        className={classnames(item.className, {
          active: item.href === path,
        })}
        {...menuItemProps}
      >
        {item.href ? (
          <Link className="ant-menu-link" to={item.href}>
            {item.label}
          </Link>
        ) : (
          item.label
        )}
      </ParentComponent.NavItem>
    );
  });
}

export function generateDefaultOpenKeys(menus, pathname) {
  let defaultOpenKeys = [];
  const cleanedPath = pathname.split("/")[1];

  const findKey = (menuItems) => {
    for (const menu of menuItems) {
      if (menu.key === cleanedPath) {
        defaultOpenKeys.push(menu.key);
      } else if (menu.subItems) {
        const subItem = menu.subItems.find(
          (subItem) => subItem.key === cleanedPath
        );
        if (subItem) {
          defaultOpenKeys.push(menu.key);
        }
        findKey(menu.subItems);
      }
    }
  };

  findKey(menus);
  return defaultOpenKeys;
}
