const PREFIX = "/api";

const API_VAR = {
  EVENT: PREFIX + "/blockchain/event",
  ASSET: PREFIX + "/asset",
  LIKED: PREFIX + "/liked",
  KEYWORD: PREFIX + "/keyword",
  AI: PREFIX + "/v1/brain-tumor",
  SPINE: "/spinefracture/",
  PNEUMONIA: "/pneumonia/",
  PNEUMONIA_PACS:"/pneumonia_url/",
  NOTIFICATIONS: PREFIX + "/notifications",
  USERS: PREFIX + "/datahub-user",
  LOGIN: PREFIX + "/blockchain/auth/signature-login",
  LOGOUT: PREFIX + "/blockchain/auth/signature-logout",
};

export const API_ROUTE = {
  ASSET: {
    UPDATE_VIEWS: "/asset-views-update",
    STATISTIC: "/statistic",
    PACS: "/pacs",
    SYNCHRONIZED_ASSET: "/synchronized-asset",
  },
  NOTIFICATIONS: {
    READ_ALL: "/read-all",
    DELETE_ALL: "/delete-all",
  },
};

export default API_VAR;
